import { ref } from 'vue'
import { isFTLOnlyCountry } from '@/utils/waypoint.utils'
import { useGoods } from '@/composables/goods'
import type { Vehicle } from '@/utils/vehicle'
import { BinPacking2D, vehicles } from '@/utils/vehicle'
import { useQuoteStore } from '@/stores/quote'
import type { Good, QuoteByHash, QuoteByID } from '@apiTypes'

export function useQuote(): {
  isFTL: (currentTotalWeight: number, goods: Good[], vehicle: Vehicle) => boolean
  quoteByHash: ComputedRef<QuoteByHash>
  loading: Ref<boolean>
  error: Ref<string>
  quoteByID: ComputedRef<QuoteByID>
  updateQuote: (payload: Partial<QuoteByID>) => Promise<void>
  updateQuoteByHash: (payload: Partial<QuoteByHash>) => Promise<void>
  createQuote: (payload: Partial<QuoteByID>) => Promise<void> } {
  const loading = ref(false)
  const error = ref('')
  const quoteStore = useQuoteStore()
  const { totalWeight } = useGoods()
  const quoteByID = computed(() => quoteStore.quoteByID)
  const quoteByHash = computed(() => quoteStore.quoteByHash)

  const createQuote = async (payload: Partial<QuoteByID>) => {
    loading.value = true
    try {
      await quoteStore.createQuote(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  const isFTL = (currentTotalWeight: number, goods: Good[], vehicle: Vehicle) => {
    const binPacking = new BinPacking2D(vehicle, goods)
    const fillPercentage = binPacking.fitPackages().fillPercentage
    const halfSpaceOrMoreOccupied = fillPercentage >= 50

    const isFTLCountry = isFTLOnlyCountry(quoteByID.value.waypoints || [])

    return currentTotalWeight > 500 || halfSpaceOrMoreOccupied || isFTLCountry
  }

  const updateQuote = async (payload: Partial<QuoteByID>) => {
    loading.value = true
    try {
      const currentTotalWeight = totalWeight.value || 0

      if (isFTL(currentTotalWeight, payload.goods || [], vehicles[0]))
        payload.ftl = true

      await quoteStore.updateQuote(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  const updateQuoteByHash = async (payload: Partial<QuoteByHash>) => {
    loading.value = true
    try {
      await quoteStore.updateQuoteByHash(payload)
      error.value = ''
    }
    catch (e: any) {
      error.value = e.message
    }
    finally {
      loading.value = false
    }
  }

  return { loading, error, createQuote, updateQuote, updateQuoteByHash, quoteByID, quoteByHash, isFTL }
}
