import { parsePhoneNumber } from 'awesome-phonenumber'
import { string } from 'yup'

export const phoneNumberValidator = string().test('is-valid-phone', 'Invalid phone number, must include country prefix', value => {
  if (!value)
    return true

  const phoneNumber = parsePhoneNumber(value)

  return phoneNumber && phoneNumber.valid && phoneNumber.number.e164 === value
})

export const emailValidator = string()
  .test('is-valid-email', 'Invalid email format', value => {
    if (!value)
      return false

    const [local, domain] = value.split('@')

    if (!domain || !domain.includes('.'))
      return false

    const [name, extension] = domain.split('.')

    return !!(name && extension)
  })
