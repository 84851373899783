import type { AxiosError } from 'axios'
import { flattenObject } from '@/utils'
import { Api } from '@apiTypes'
import { clearAuthDataAndRedirect, hasRefreshToken, refreshTokenHelper, setHeaders } from '@/auth/utils'

const api = new Api()
const apiAxiosInstance = api.instance
let _refreshTokenRequest: Promise<unknown> | null = null

if (import.meta.env.MODE === 'development' && (!window.location.port || window.location.port !== '80')) {
  // This is fix for development env, if you want to access via FE port (currently 5173) directly
  const newBaseUrl = `http://${window.location.hostname}:8000/`

  console.log('[AXIOS] Changing API base URL', newBaseUrl)
  apiAxiosInstance.defaults.baseURL = newBaseUrl
}

apiAxiosInstance.interceptors.request.use(config => {
  if (import.meta.env.MODE === 'development')
    console.log('[AXIOS] Request Interceptors', config)

  setHeaders(config)

  return config
}, (error: any) => {
  console.error('[AXIOS] Request Interceptors Error', error)

  return Promise.reject(error)
})

apiAxiosInstance.interceptors.response.use(response => {
  if (import.meta.env.VITE_AXIOS_DEBUG === 'true')
    console.log('[AXIOS] Response Interceptors', response)

  return response
}, async (err: AxiosError) => {
  console.error('[AXIOS] Response Interceptors Error', err)
  switch (err.response?.status) {
    case 400:
      err.response.data = flattenObject(err.response.data)
      break
    case 401: {
      const config = err.config!

      if (hasRefreshToken() && !config.skipAuthRefresh) {
        _refreshTokenRequest ??= refreshTokenHelper()

        return _refreshTokenRequest.then(() => {
          setHeaders(config)
          config.skipAuthRefresh = true

          // eslint-disable-next-line promise/no-return-wrap
          return apiAxiosInstance.request(config).catch(error => Promise.reject(error))
        }).catch(refreshTokenError => {
          if (refreshTokenError.response?.status === 401) {
            setHeaders(config)
            config.skipAuthRefresh = true

            // we should try it one more time because token can be refreshed in another tab
            return apiAxiosInstance.request(config).catch(error => {
              if (refreshTokenError.response?.status === 401)
                clearAuthDataAndRedirect()

              // eslint-disable-next-line promise/no-return-wrap
              return Promise.reject(error)
            })
          }

          // eslint-disable-next-line promise/no-return-wrap
          return Promise.reject(refreshTokenError)
        }).finally(() => {
          _refreshTokenRequest = null
        })
      }
      else if (!config.skipLogout) {
        clearAuthDataAndRedirect()
      }
    }
  }

  return Promise.reject(err)
})

apiAxiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'
apiAxiosInstance.defaults.xsrfCookieName = 'csrftoken'

export const axiosInstance = apiAxiosInstance
export default api
