<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm'
import { Form } from 'vee-validate'
import { useToast } from 'vue-toastification'
import { bool, object, string } from 'yup'
import { useAuthStore } from '@store/auth'
import { emailValidator, phoneNumberValidator } from '@/utils/validators'
import { useUtmStore } from '@store/utm'
import type { Feedback } from '@apiTypes'
import { useFeedback } from '@/composables/feedbacks'
import Checkbox from '@/components/form/Checkbox.vue'
import TextInput from '@/components/form/TextInput.vue'

const form = ref()
const { t } = useI18n()
const { createFeedback, loading } = useFeedback()
const toast = useToast()
const authStore = useAuthStore()
const gtm = useGtm()
const utmStore = useUtmStore()

const termsTitle = t('I agree with <a>Terms and conditions</a>')
const termsTitleWithLink = termsTitle.replace('<a>', '<a href="/static/files/terms.pdf" target="_blank">')

const initialValues = {
  user: authStore.currentUser,
}

const validationSchema = object({
  user: object().shape({
    name: string().required(t('Name is required')),
    email: emailValidator.required(t('Email is required')),
    phone: phoneNumberValidator,
  }),
  agree: bool()
    .required('You must agree to the terms and conditions')
    .oneOf([true], 'You must agree to the terms and conditions'),
})

async function onSubmit(feedback: Feedback) {
  if (utmStore.utm.utm_source)
    feedback.utm_parameters = utmStore.utm
  await createFeedback(feedback)
  toast.success(t('Your message has been submitted. Thank you!'))
  gtm?.trackEvent({ event: 'contact_us_form', action: 'click' })
  form.value.resetForm()
}
</script>

<template>
  <Form
    ref="form"
    :initial-values="initialValues"
    :validation-schema="validationSchema"
    @submit="onSubmit"
  >
    <div class="row">
      <div class="col-12">
        <TextInput name="user.name" :label="$t('YOUR NAME')" />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <TextInput name="user.email" :label="$t('YOUR WORK E-MAIL')" />
      </div>
      <div class="col-6">
        <TextInput name="user.phone" :label="$t('PHONE NUMBER (OPTIONAL)')" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <TextInput name="feedback_text" multiline :label="$t('MESSAGE')" />
      </div>
    </div>

    <Checkbox
      class="fw-bold" :html-label="termsTitleWithLink"
      name="agree"
      validation
    />
    <div class="d-flex justify-content-md-start justify-content-center">
      <LoadingButton
        type="submit"
        style="min-width: 60%"
        :loading="loading"
        title="Send"
      />
    </div>
  </Form>
</template>
