<script setup lang="ts">
import { useField } from 'vee-validate'
import { useGoogleMaps } from '@/composables/google-maps'
import { WaypointType } from '@apiTypes'
import type { Address, AddressComponents } from '@/utils'
import { getAddressObject, isNullOrUndefined } from '@/utils'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  name: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  multiline: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  autocomplete: {
    type: String,
    default: 'off',
  },
  placeholder: {
    type: String,
    default: '',
  },
  append: {
    type: String,
    default: '',
  },
  labelClass: {
    type: String,
    default: '',
  },
  useGoogleMapsAutocomplete: {
    type: Boolean,
    default: false,
  },
  cssClass: {
    type: String,
    default: '',
  },
  min: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits<{
  (e: 'addressChange', address: Address): void
  (e: 'change', value: number | string): void
}>()

const nameRef = toRef(props, 'name')
const hasValue = ref(false)
const gmvAutoCompleteInput = ref<HTMLInputElement>()

const googlePlaceFields = ['address_components', 'formatted_address', 'types', 'geometry', 'name']

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  setValue: setFieldValue,
  resetField,
} = useField(nameRef)

const reset = () => resetField({ value: '' })
const setValue = (value: string) => setFieldValue(value)

defineExpose({
  reset, setValue,
})

function onInput(e?: Event) {
  handleChange(e)

  hasTextOrNumber(inputValue.value)

  const emittedValue = props.type === 'number' ? Number(inputValue.value) : inputValue.value

  emit('change', emittedValue as string | number)
}

function hasTextOrNumber(inputData: unknown) {
  if (typeof inputData === 'string')
    hasValue.value = inputData.trim() !== ''

  hasValue.value = !Number.isNaN(inputData)
}

async function setPlace(value: google.maps.places.PlaceResult) {
  const addressComponents: AddressComponents = value.address_components

  const address = getAddressObject(addressComponents)
  const nameTypes = ['point_of_interest', 'establishment']

  if (value.types && value.types.some(type => nameTypes.includes(type)))
    address.name = value.name as string

  if (address.point) {
    const lat = value.geometry?.location.lat()
    const lng = value.geometry?.location.lng()

    address.point.type = WaypointType.Point

    if (!address.postal_code && lat && lng) {
      const { reverseGeocode } = useGoogleMaps()

      address.postal_code = await reverseGeocode(lat, lng)
    }

    if (!isNullOrUndefined(lat) && !isNullOrUndefined(lng))
      address.point.coordinates = [lng, lat]
    else
      address.point.coordinates = undefined
  }

  emit('addressChange', address)
}
</script>

<template>
  <div class="validation-input" :class="{ 'has-error': !!errorMessage }">
    <label
      v-if="label" class="form-label d-block"
      :class="[labelClass, { required }]" :for="name"
    >{{ label }}</label>
    <textarea
      v-if="multiline" :id="name"
      class="form-control d-block user-input" :class="cssClass"
      :name="name" :disabled="disabled"
      :value="inputValue" :placeholder="placeholder"
      @input="onInput" @blur="handleBlur"
    />
    <GmvAutocomplete
      v-else-if="useGoogleMapsAutocomplete"
      :slot-ref="gmvAutoCompleteInput"
      :set-fields-to="googlePlaceFields"
      @place_changed="setPlace"
    >
      <template #default>
        <input
          ref="gmvAutoCompleteInput"
          class="form-control d-block user-input"
          :class="[cssClass, { 'border-danger': !hasValue && !disabled }]"
          :placeholder="placeholder"
          :disabled="disabled"
          :value="inputValue"
          @input="onInput"
        >
      </template>
    </GmvAutocomplete>
    <div v-else class="input-group">
      <input
        :id="name"
        class="form-control d-block user-input"
        :class="[cssClass, { 'border-danger': !hasValue && (name.includes('unit_weight') || name.includes('height')) && !disabled }]"
        :name="name" :min="min"
        :disabled="disabled"
        :type="type" :value="inputValue"
        :placeholder="placeholder" :autocomplete="autocomplete"
        @input="onInput" @blur="handleBlur"
      >
      <span v-if="append" class="input-group-text bg-light">{{ append }}</span>
    </div>

    <ValidationMessage :error-message="errorMessage" />
  </div>
</template>
