<script setup lang="ts">
import { ref } from 'vue'
import { Form } from 'vee-validate'
import { useToast } from 'vue-toastification'
import { object, string } from 'yup'
import { useGtm } from '@gtm-support/vue-gtm'
import { useAuthStore } from '@store/auth'
import { usePostalCodes } from '@/composables/postal-codes'
import { TypeEnum } from '@apiTypes'
import type { CountryEnum, QuoteByID, Waypoint } from '@apiTypes'
import { Countries } from '@/utils/countries'
import type { Address } from '@/utils'
import { isPostalCodeNotAllowed } from '@/utils'
import { useQuote } from '@/composables/quotes'

const { createQuote, loading, error } = useQuote()
const { currentUser } = storeToRefs(useAuthStore())
const { postalCodes } = usePostalCodes()

const quote = ref<Partial<QuoteByID>>({
  waypoints: [] as Waypoint[],
})

const router = useRouter()
const gtm = useGtm()
const form = ref()
const toast = useToast()
const { t } = useI18n()

const validationSchema = object({
  pickup: string().required(t('Pickup place is required')),
  delivery: string().required(t('Delivery place is required')),
})

function updateAddress(type: TypeEnum.Pickup | TypeEnum.Delivery, address: Address, sequence: number) {
  if (!address.city || !address.country) {
    toast.warning(t('Please specify {type} which has at least city and country.', { type }))

    return
  }

  const waypoint: Partial<Waypoint> = {
    city: address.city,
    country: address.country as CountryEnum,
    postcode: address.postal_code,
    street: address.street,
    street_number: address.home,
    name: address.name,
    point: address.point,
    sequence,
    type,
  }

  if (!quote.value.waypoints)
    quote.value.waypoints = []

  const existingIndex = quote.value.waypoints?.findIndex(wp => wp.type === type)

  if (existingIndex > -1)
    quote.value.waypoints[existingIndex] = waypoint as Waypoint
  else
    quote.value.waypoints?.push(waypoint as Waypoint)
}

async function onSubmit() {
  const pickupAddress = quote.value.waypoints?.find(wp => wp.type === TypeEnum.Pickup)
  const deliveryAddress = quote.value.waypoints?.find(wp => wp.type === TypeEnum.Delivery)

  const { isPickupNotAllowed, isDeliveryNotAllowed } = isPostalCodeNotAllowed(pickupAddress, deliveryAddress, postalCodes)

  if (!pickupAddress?.city || !pickupAddress?.country) {
    toast.error(t('You need to specify { pickup } which has at least city and country.', { pickup: TypeEnum.Pickup }))

    return
  }

  if (!Countries.includes(pickupAddress.country) || isPickupNotAllowed) {
    toast.warning(t('Looks like you would like to load goods in a country or region our system is not able to quote automatically. Don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.'))

    return
  }

  if (!deliveryAddress?.city || !deliveryAddress?.country) {
    toast.error(t('You need to specify { delivery } which has at least city and country.', { delivery: TypeEnum.Delivery }))

    return
  }

  if (!Countries.includes(deliveryAddress.country) || isDeliveryNotAllowed) {
    toast.warning(t('Looks like you would like to unload goods in a country or region our system is not able to quote automatically. Don\'t worry, we\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.'))

    return
  }
  const pickupCoordinates = pickupAddress.point?.coordinates
  const deliveryCoordinates = deliveryAddress.point?.coordinates

  if (pickupCoordinates && deliveryCoordinates && pickupCoordinates.toString() === deliveryCoordinates.toString()) {
    toast.error(t('The pickup and delivery cannot be the same'))

    return
  }
  if (currentUser.value)
    quote.value.user = currentUser.value

  await createQuote(quote.value)
  if (!error.value) {
    toast.clear()
    await router.push('/free-quote')
    gtm?.trackEvent({ event: 'get_price', action: 'click' })
  }
}

watch(error, value => value && toast.error(value))
</script>

<template>
  <Form
    ref="form" class="row gy-2 gx-3 my-2 align-items-center free-quote-form"
    :validation-schema="validationSchema"
    @submit="onSubmit"
  >
    <div class="col-sm pe-sm-3">
      <TextInput
        :value="quote.waypoints?.[0]?.city" name="pickup"
        :label="$t('From')"
        required
        use-google-maps-autocomplete
        :placeholder="$t('Enter pickup location')"
        css-class="form-control-lg w-100"
        @address-change="updateAddress(TypeEnum.Pickup, $event, 1)"
      />
    </div>
    <div class="col-sm px-sm-3">
      <TextInput
        :value="quote.waypoints?.[1]?.city" name="delivery"
        :label="$t('To')"
        required
        use-google-maps-autocomplete
        :placeholder="$t('Enter delivery location')"
        css-class="form-control-lg w-100"
        @address-change="updateAddress(TypeEnum.Delivery, $event, 2)"
      />
    </div>
    <div class="col-sm ps-sm-3">
      <LoadingButton
        class="btn btn-lg btn-primary w-100" type="submit"
        style="min-width: 50%"
        :loading="loading"
        :title="$t('Get your price')"
      />
    </div>
  </Form>
</template>

<style lang="scss">
.free-quote-form {
  .input-group {
    >div {
      flex-basis: 100%;
    }
  }
}
</style>
