import type { PostalCodes } from '@apiTypes'
import { usePostalCodeStore } from '@store/postal-codes'

export function usePostalCodes(): { postalCodes: Ref<PostalCodes> } {
  const error = ref('')
  const postalCodesStore = usePostalCodeStore()
  const postalCodes: Ref<PostalCodes> = ref({ expensive_regions: [], not_allowed: [] })

  postalCodesStore.getSpecialPostalCodes()
    .then(codes => postalCodes.value = codes)
    .catch(e => error.value = e)

  return { postalCodes }
}
