{
  "From": "From",
  "To": "To",
  "Get your price": "Get your price",
  "City": "City",
  "Page not found": "Page not found",
  "This is not the page you were looking for.": "This is not the page you were looking for.",
  "Back": "Back",
  "Contact us": "Contact us",
  "If you have any questions please use the following contacts.": "If you have any questions please use the following contacts.",
  "Privacy policy": "Privacy policy",
  "Terms and Conditions": "Terms and Conditions",
  "Powered by SWIDA Innovative © 2023 - instacarrier.eu": "Powered by SWIDA Innovative © 2023 - instacarrier.eu",
  "Need help?": "Need help?",
  "How it works": "How it works",
  "Services and benefits": "Services and benefits",
  "Get FREE Quote": "Get FREE Quote",
  "It is simple. Choose what you need to transport and Swida Innovative takes care of everything.": "It is simple. Choose what you need to transport and Swida Innovative takes care of everything.",
  "Instant quote and order": "Instant quote and order",
  "Send a transportation request": "Send a transportation request",
  "Get the quote": "Get the quote",
  "Book transport": "Book transport",
  "Transport confirmed": "Transport confirmed",
  "Just in 2 hours": "Just in 2 hours",
  "Enjoy delivery and online dashboard/tracking": "Enjoy delivery and online dashboard/tracking",
  "Truck\\'s licence plate info": "Truck\\'s licence plate info",
  "Pickup confirmed": "Pickup confirmed",
  "Delivery tracking": "Delivery tracking",
  "Delivery confirmed": "Delivery confirmed",
  "Payment": "Payment",
  "Get FREE quote now": "Get FREE quote now",
  "Get the instant transport price.": "Get the instant transport price.",
  "InstaCarrier is a service provided by SWIDA Innovative company. We provide more than 40 000 transports around Europe per year. See what is included in the service.": "InstaCarrier is a service provided by SWIDA Innovative company. We provide more than 40 000 transports around Europe per year. See what is included in the service.",
  "Get free quote now": "Get free quote now",
  "Custom transport request": "Custom transport request",
  "Create a quick transport request.": "Create a quick transport request.",
  "Price quote": "Price quote",
  "We provide instant freight quotes without unnecessary waiting.": "We provide instant freight quotes without unnecessary waiting.",
  "Online booking": "Online booking",
  "Get the experience of booking freight shipments in 21st century.": "Get the experience of booking freight shipments in 21st century.",
  "Just 2 hours": "Just 2 hours",
  "From order to pickup from your location.": "From order to pickup from your location.",
  "License plate provided": "License plate provided",
  "Truck\\'s license plate is shared with you immediately after order confirmation.": "Truck\\'s license plate is shared with you immediately after order confirmation.",
  "Pickup and delivery confirmation": "Pickup and delivery confirmation",
  "Is provided online by the Detail Page and notifications.": "Is provided online by the Detail Page and notifications.",
  "Stay updated during the transport.": "Stay updated during the transport.",
  "Pay later": "Pay later",
  "For verified clients, payment by invoice is possible.": "For verified clients, payment by invoice is possible.",
  "not-found": "not-found",
  "Transport Request": "Transport Request",
  "Describe your transport": "Describe your transport",
  "satisfied clients": "satisfied clients",
  "transports on the road right now": "transports on the road right now",
  "years on market": "years on market",
  "Thank you for the price request.": "Thank you for the price request.",
  "This is a beta version of the InstaCarrier platform with a limited capacity for automated processing. However, we will be delighted to attend to you in person.": "This is a beta version of the InstaCarrier platform with a limited capacity for automated processing. However, we will be delighted to attend to you in person.",
  "Please choose your preferred method of contact:": "Please choose your preferred method of contact:",
  "Do you have an idea about how to improve our services?": "Do you have an idea about how to improve our services?",
  "Thank you.": "Thank you.",
  "We will contact you ASAP": "We will contact you ASAP",
  "VAT": "VAT",
  "Checking limit": "Checking limit",
  "Retry": "Retry",
  "The entered VAT ID was not found. Please enter company data manually.": "The entered VAT ID was not found. Please enter company data manually.",
  "Enter your billing information": "Enter your billing information",
  "Vat number": "Vat number",
  "Country": "Country",
  "Autofill company details": "Autofill company details",
  "Add information manually": "Add information manually",
  "Company name": "Company name",
  "Address": "Address",
  "ZIP": "ZIP",
  "Continue": "Continue",
  "Billing information": "Billing information",
  "Payment method": "Payment method",
  "Confirmation": "Confirmation",
  "Could not initialize payment": "Could not initialize payment",
  "Could not confirm order": "Could not confirm order",
  "Could not save information": "Could not save information",
  "Could not save payment": "Could not save payment",
  "Order confirmation and payment": "Order confirmation and payment",
  "Order confirmation": "Order confirmation",
  "Price is above insurance limit": "Price is above insurance limit",
  "Select payment method": "Select payment method",
  "Please choose and confirm payment method you prefer": "Please choose and confirm payment method you prefer",
  "City is required": "City is required",
  "Country is required": "Country is required",
  "Company or name": "Company or name",
  "Street and Number": "Street and Number",
  "Close": "Close",
  "Get your price in minutes": "Get your price in minutes",
  "Pickup in 2 hours": "Pickup in 2 hours",
  "Get a full control over your shipment with live shipment dashboard.": "Get a full control over your shipment with live shipment dashboard.",
  "Order now – Pay later!": "Order now – Pay later!",
  "Remove": "Remove",
  "COUNT OF UNITS": "COUNT OF UNITS",
  "Type of Packaging": "Type of Packaging",
  "Unit Size": "Unit Size",
  "Unit Width": "Unit Width",
  "Unit height": "Unit height",
  "Unit Weight": "Unit Weight",
  "Stackable packaging": "Stackable packaging",
  "The dimensions of your packages specified in your transportation request exceed the floor space of the vehicle you requested, but don\\'t worry, we\\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.": "The dimensions of your packages specified in your transportation request exceed the floor space of the vehicle you requested, but don\\'t worry, we\\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.",
  "The vehicle you chose for your transportation request cannot accommodate the weight of your shipment, but don\\'t worry, we\\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.": "The vehicle you chose for your transportation request cannot accommodate the weight of your shipment, but don\\'t worry, we\\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.",
  "Vehicles": "Vehicles",
  "+ Add another cargo": "+ Add another cargo",
  "Total weight": "Total weight",
  "Special requirements": "Special requirements",
  "Temperature-controlled vehicle": "Temperature-controlled vehicle",
  "TailLift": "TailLift",
  "ADR": "ADR",
  "We found a transport for you!": "We found a transport for you!",
  "Your price quote is ready. Let us know your details.": "Your price quote is ready. Let us know your details.",
  "I agree with <a>Terms and conditions</a>": "I agree with <a>Terms and conditions</a>",
  "Email is required": "Email is required",
  "Name is required": "Name is required",
  "Phone is required": "Phone is required",
  "Your name and surname": "Your name and surname",
  "Work e-mail": "Work e-mail",
  "Phone number": "Phone number",
  "YOUR NAME": "YOUR NAME",
  "YOUR WORK E-MAIL": "YOUR WORK E-MAIL",
  "PHONE NUMBER (OPTIONAL)": "PHONE NUMBER (OPTIONAL)",
  "MESSAGE": "MESSAGE",
  "Pickup place is required": "Pickup place is required",
  "Delivery place is required": "Delivery place is required",
  "Enter pickup location": "Enter pickup location",
  "Enter delivery location": "Enter delivery location",
  "The selected datetime cannot be in the past": "The selected datetime cannot be in the past",
  "Choose one": "Choose one",
  "Count is required": "Count is required",
  "Count should be greater than or equal to 1": "Count should be greater than or equal to 1",
  "Length is required": "Length is required",
  "Length should be greater than or equal to 1": "Length should be greater than or equal to 1",
  "Width is required": "Width is required",
  "Width should be greater than or equal to 1": "Width should be greater than or equal to 1",
  "Height is required": "Height is required",
  "Height should be greater than or equal to 1": "Height should be greater than or equal to 1",
  "Unit weight is required": "Unit weight is required",
  "Unit weight should be greater than or equal to 1": "Unit weight should be greater than or equal to 1",
  "PICKUP": "PICKUP",
  "DELIVERY": "DELIVERY",
  "Transport Duration": "Transport Duration",
  "Estimated Distance": "Estimated Distance",
  "Freight details": "Freight details",
  "Define your cargo details below, you can select from various packaging and vehicle types.": "Define your cargo details below, you can select from various packaging and vehicle types.",
  "Additional notes": "Additional notes",
  "Anything else you want to add?": "Anything else you want to add?",
  "Enter your notes here...": "Enter your notes here...",
  "Confirm": "Confirm",
  "Send": "Send",
  "Your price quote is on its way.": "Your price quote is on its way.",
  "We are very sorry the InstaCarrier platform did not fulfill your expectations, yet. Let us work on it for a few more weeks and inform you about its full glorious launch to the world.": "We are very sorry the InstaCarrier platform did not fulfill your expectations, yet. Let us work on it for a few more weeks and inform you about its full glorious launch to the world.",
  "Your price quote is on its way!": "Your price quote is on its way!",
  "Check your inbox, you can expect a price quote from InstaCarrier in a few moments.": "Check your inbox, you can expect a price quote from InstaCarrier in a few moments.",
  "Having any questions?": "Having any questions?",
  "You have to choose one.": "You have to choose one.",
  "Invalid contact method": "Invalid contact method",
  "Order summary": "Order summary",
  "Check the order you entered and confirm its correctness with the button at the bottom.": "Check the order you entered and confirm its correctness with the button at the bottom.",
  "Your order has been sent for processing. An assigned operator will contact you shortly.": "Your order has been sent for processing. An assigned operator will contact you shortly.",
  "Route details": "Route details",
  "Cargo information": "Cargo information",
  "Your price without VAT": "Your price without VAT",
  "Distance": "Distance",
  "Duration": "Duration",
  "URL copied successfully": "URL copied successfully",
  "Transportation order": "Transportation order",
  "Finish your transportation order.": "Finish your transportation order.",
  "Share a price quote": "Share a price quote",
  "Offer expires in": "Offer expires in",
  "Pickup": "Pickup",
  "Delivery": "Delivery",
  "You\\'ve reached the height limit of selected vehicle, but don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.": "You\\'ve reached the height limit of selected vehicle, but don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.",
  "You\\'ve reached the width limit of selected vehicle, but don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.": "You\\'ve reached the width limit of selected vehicle, but don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.",
  "You\\'ve reached the length limit of selected vehicle, but don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.": "You\\'ve reached the length limit of selected vehicle, but don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.",
  "Your message has been submitted. Thank you!": "Your message has been submitted. Thank you!",
  "Looks like you would like to load goods in a country our system is not able to quote automatically. Don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.": "Looks like you would like to load goods in a country our system is not able to quote automatically. Don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.",
  "Looks like you would like to unload goods in a country our system is not able to quote automatically. Don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.": "Looks like you would like to unload goods in a country our system is not able to quote automatically. Don\\'t worry, we\\'ll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.",
  "Please note that the estimated shipping time for your shipping request is longer than the difference between the loading and unloading time you entered. Adjust the unloading date to a later time so that the driver can arrive at the unloading location.": "Please note that the estimated shipping time for your shipping request is longer than the difference between the loading and unloading time you entered. Adjust the unloading date to a later time so that the driver can arrive at the unloading location.",
  "We did the math and it seems that the interval between loading and unloading you entered is way too long. The interval cannot be greater than four times the duration of the transport.": "We did the math and it seems that the interval between loading and unloading you entered is way too long. The interval cannot be greater than four times the duration of the transport.",
  "Please specify {type} which has at least city and country.": "Please specify {type} which has at least city and country.",
  "You need to specify { pickup } which has at least city and country.": "You need to specify { pickup } which has at least city and country.",
  "You need to specify { delivery } which has at least city and country.": "You need to specify { delivery } which has at least city and country.",
  "{ type } date and time is required": "{ type } date and time is required",
  "Share with us your opinion at ": "Share with us your opinion at ",
  "Do you have an idea about how to improve our services? Share it with us at ": "Do you have an idea about how to improve our services? Share it with us at ",
  "Latest news, guides and posts": "Latest news, guides and posts",
  "Stay updated with the latest insights and tips from our transport and technology experts.": "Stay updated with the latest insights and tips from our transport and technology experts.",
  "Go to InstaCarrier Blog": "Go to InstaCarrier Blog",
  "Please email us at the following email address listed below or by filling out the form.": "Please email us at the following email address listed below or by filling out the form.",
  "Blog": "Blog",
  "It is simple. Choose what you need to transport, and SWIDA Innovative will take care of the rest.": "It is simple. Choose what you need to transport, and SWIDA Innovative will take care of the rest.",
  "Get a FREE quote now": "Get a FREE quote now",
  "Get a free quote now": "Get a free quote now",
  "Get the experience of booking freight shipments in the 21st century.": "Get the experience of booking freight shipments in the 21st century.",
  "The quote has been canceled, please create a new one.": "The quote has been canceled, please create a new one.",
  "years on the market": "years on the market",
  "Unit length": "Unit length",
  "The selected datetime must be within the next 30 days": "The selected datetime must be within the next 30 days",
  "Date and time is required": "Date and time is required",
  "Invalid date and time": "Invalid date and time",
  "Count should not be greater than 200": "Count should not be greater than 200",
  "LATEST PICKUP": "LATEST PICKUP",
  "LATEST DELIVERY": "LATEST DELIVERY",
  "Order confirmed": "Order confirmed",
  "Latest Pickup": "Latest Pickup",
  "Latest Delivery": "Latest Delivery",
  "hour": "hour | hours"
}
