export const Countries = [
  'DE', // Germany
  'AT', // Austria
  'FR', // France
  'IT', // Italy
  'LI', // Liechtenstein
  'ES', // Spain
  'PT', // Portugal
  'CH', // Switzerland
  'SE', // Sweden
  'NO', // Norway
  'FI', // Finland
  'SI', // Slovenia
  'HR', // Croatia
  'BA', // Bosnia & Herzegovina
  'RS', // Serbia
  'GR', // Greece
  'GB', // United Kingdom
  'DK', // Denmark
  'BE', // Belgium
  'NL', // Netherlands
  'LU', // Luxembourg
  'BG', // Bulgaria
  'RO', // Romania
  'LV', // Latvia
  'LT', // Lithuania
  'EE', // Estonia
  'PL', // Poland
  'SK', // Slovakia
  'CZ', // Czechia
  'HU', // Hungary
  'TR', // Turkey
]

export const FTL_ONLY_COUNTRIES = [
  'CH',
  'DK',
  'ES',
  'PT',
  'FI',
  'GR',
  'HR',
  'BA',
  'RS',
  'LV',
  'LT',
  'EE',
  'SE',
  'NO',
  'TR',
]
