import { acceptHMRUpdate, defineStore } from 'pinia'
import type { PostalCodes } from '@apiTypes'
import api from '@api'

export const usePostalCodeStore = defineStore('postal-codes', {
  state: () => ({
    postal_codes: {} as PostalCodes,
    lastFetched: 0 as number,
  }),
  actions: {
    async getSpecialPostalCodes() {
      const currentTime = new Date().getTime()
      const refreshInterval = 300000

      if (!this.lastFetched || currentTime - this.lastFetched > refreshInterval) {
        const response = await api.postalCodesRetrieve()

        this.postal_codes = response.data
      }

      return this.postal_codes
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePostalCodeStore, import.meta.hot))
