import type { Good } from '@/stores/quote'
import { TypeEnum } from '@apiTypes'
import type { QuoteByHash, QuoteByID } from '@apiTypes'

export function quoteTotalWeight(goods: Good[]): number {
  return goods?.reduce((sum, good) => sum + (good.count || 0) * (good.unit_weight || 0), 0) || 1000
}

export function formatQuoteTotalWeight(goods: Good[]): string {
  const totalWeight = quoteTotalWeight(goods)

  return totalWeight ? `${totalWeight} kg` : ' - kg'
}

export function formatQuoteDuration(quote: QuoteByID | QuoteByHash | undefined): string {
  const { t } = useI18n()

  const durationInHours = quote?.duration ? +quote.duration : 0

  return `${durationInHours} ${t('hours')}`
}

export function formatQuotePrice(quote: QuoteByHash | undefined): { confirmationPrice: string; detailPrice: string; discountAmount: string } {
  const { n, t } = useI18n()

  const currencyOptions = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }

  // todo refactor this to component???
  const confirmationPrice = quote?.selected_price_option?.pricing.final_price ? n(+quote?.selected_price_option?.pricing.final_price, currencyOptions, 'sk-SK') : ''

  const discountAmount = quote?.discount_usage?.[0]?.discount?.amount
    ? n(+quote.discount_usage[0].discount.amount, currencyOptions, 'sk-SK')
    : ''

  const formattedDiscount = discountAmount
    ? `<br><span class="text-muted fw-lighter fs-6">${t('Discount: -')}${discountAmount}</span>`
    : ''

  const detailPrice = quote?.selected_price_option?.pricing.final_price
    ? `<p class="m-0 p-0">${n(+quote?.selected_price_option?.pricing.final_price, currencyOptions, 'sk-SK')}</p>
       <span class="m-0 p-0 fs-6">${t('(VAT not included)')}</span>
       ${formattedDiscount}`
    : ''

  return {
    confirmationPrice,
    detailPrice,
    discountAmount,
  }
}

export function formatQuoteDistance(quote: QuoteByID | QuoteByHash | undefined): string {
  return quote?.distance ? `~ ${Math.floor(+quote.distance)} km` : '- km'
}

export async function scrollToFirstError(form: { validate: () => Promise<{ errors: Record<string, string[]> }> }): Promise<void> {
  const validationResult = await form.validate()
  const errorKeys = Object.keys(validationResult.errors)

  if (errorKeys.length > 0) {
    const firstErrorKey = errorKeys[0]
    const fieldElement = document.querySelector(`[name="${firstErrorKey}"]`)
    if (fieldElement)
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export function formatDateTimeRange(lower: string, upper: string, mode: TypeEnum.Pickup | TypeEnum.Delivery = TypeEnum.Pickup): string {
  const lowerDate = new Date(lower)
  const upperDate = new Date(upper)

  const formatDate = (date: Date) => date.toLocaleDateString('sk-SK', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).replace(/\//g, '.')

  const formatTime = (date: Date) => date.toLocaleTimeString('sk-SK', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  if (mode === TypeEnum.Delivery)
    return `${formatDate(upperDate)} ${formatTime(upperDate)}`

  return `${formatDate(lowerDate)} ${formatTime(lowerDate)} - ${formatDate(upperDate)} ${formatTime(upperDate)}`
}
