import { ref } from 'vue'
import type { BlacklistedEmailDomain, ExpensiveCountryRegion, NotAllowedPostalCode, PostalCodes, Waypoint, WaypointType } from '@apiTypes'

export function flattenObject(obj: any): Record<string, any> {
  const result: Record<string, any> = {}

  function flatten(nested: any, prevKey = '') {
    for (const [key, value] of Object.entries(nested)) {
      const newKey = prevKey ? `${prevKey}.${key}` : key
      if (isArrayOfObjects(value))
        (<any[]>value).forEach((val: any, index: number) => flatten(val, `${newKey}[${index}]`))
      else if (isObject(value) && !isArray(value))
        flatten(value, newKey)
      else
        result[newKey] = value
    }
  }

  flatten(obj)

  return result
}

function isObject(obj: any): boolean {
  return typeof obj === 'object' && obj != null
}

function isArray(obj: any): boolean {
  return isObject(obj) && Array.isArray(obj)
}

function isArrayOfObjects(obj: any): boolean {
  return obj != null && Array.isArray(obj) && obj.every(val => isObject(val))
}

export function toPascalCase(str: string): string {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('')
}

export type AddressComponents = google.maps.GeocoderAddressComponent[] | undefined

export interface Address {
  point: {
    type?: WaypointType
    coordinates?: number[]
  } | null
  home: string
  postal_code: string
  street: string
  region: string
  city: string
  country: string
  name: string
}

export function getAddressObject(address_components: AddressComponents): Address {
  const ShouldBeComponent: Record<string, string[]> = {
    home: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  }

  const address: Address = {
    point: { },
    home: '',
    postal_code: '',
    street: '',
    region: '',
    city: '',
    country: '',
    name: '',
  }

  address_components?.forEach(component => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].includes(component.types[0])) {
        if (shouldBe === 'country')
          address[shouldBe as keyof Address] = component.short_name
        else
          address[shouldBe as keyof Address] = component.long_name
      }
    }
  })

  return address
}

export function generateWKTPoint(lat: number, lng: number): string {
  return `SRID=4326;POINT (${lng} ${lat})`
}

export function useWindowWidth(): Ref<number> {
  const windowWidth_ = ref(window.innerWidth)

  const handleResize = () => {
    windowWidth_.value = window.innerWidth
  }

  onMounted(() => window.addEventListener('resize', handleResize))
  onUnmounted(() => window.removeEventListener('resize', handleResize))

  return windowWidth_
}

export function isNullOrUndefined(object: any): object is null | undefined {
  return object === null || object === undefined
}

const WORKING_HOURS_START = 6
const WORKING_HOURS_END = 15

export function adjustToWorkingHours(datetime: string | undefined | null, hoursOffset: number): Date {
  const date = datetime ? new Date(datetime) : new Date()
  const initialMinutes = date.getUTCMinutes()

  let remainingHours = hoursOffset

  while (remainingHours >= 0) {
    const dayOfWeek = date.getUTCDay()

    // Saturday (6) or Sunday (0)
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      // Move to the next Monday
      date.setUTCDate(date.getUTCDate() + (dayOfWeek === 0 ? 1 : 2))
      date.setUTCHours(WORKING_HOURS_START, initialMinutes, 0, 0) // Reset
    }
    else {
      const hoursTillEndOfDay = WORKING_HOURS_END - date.getUTCHours()

      if (hoursTillEndOfDay >= remainingHours) {
        date.setUTCHours(date.getUTCHours() + remainingHours, initialMinutes, 0, 0)
        break
      }
      else {
        remainingHours -= Math.max(hoursTillEndOfDay, 0)

        date.setUTCDate(date.getUTCDate() + 1)
        date.setUTCHours(WORKING_HOURS_START, initialMinutes, 0, 0)
      }
    }
  }

  return date
}

export function splitName(name: string): [string, string] {
  const firstSpaceIndex = (name || '').indexOf(' ')
  if (firstSpaceIndex >= 0) {
    const firstPart = name.substring(0, firstSpaceIndex)
    const secondPart = name.substring(firstSpaceIndex + 1)

    return [firstPart, secondPart]
  }

  return [name || '', '']
}

export function isPostalCodeNotAllowed(pickup: Waypoint, delivery: Waypoint, postalCodes: Ref<PostalCodes>): {
  isPickupNotAllowed: boolean | undefined
  isDeliveryNotAllowed: boolean | undefined
} {
  function checkPostalCode(postalCodesDisallowedList: string[] | undefined, address: Waypoint): boolean | undefined {
    if (postalCodesDisallowedList) {
      for (const code of postalCodesDisallowedList) {
        if (address.postcode?.replace(/ /g, '').startsWith(code))
          return true
      }
    }
  }

  function findPostalCodesByCountry(codes: NotAllowedPostalCode[] | ExpensiveCountryRegion[], address: Waypoint) {
    if (codes)
      return codes.find(code => code.country === address.country)
  }

  function isCountryInDisallowedPostalCodes(address: Waypoint): boolean | undefined {
    const countryPostalCodes = findPostalCodesByCountry(postalCodes.value.not_allowed, address)
    const expensivePostalCodes = findPostalCodesByCountry(postalCodes.value.expensive_regions, address)

    return checkPostalCode(countryPostalCodes?.postal_codes, address) || checkPostalCode(expensivePostalCodes?.postal_codes, address)
  }

  const isPickupNotAllowed = isCountryInDisallowedPostalCodes(pickup)
  const isDeliveryNotAllowed = isCountryInDisallowedPostalCodes(delivery)

  return { isPickupNotAllowed, isDeliveryNotAllowed }
}

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function checkEmailDomainBlacklisted(email: string, blacklistedDomains: Ref<BlacklistedEmailDomain[]>): boolean {
  const normalizedEmail = (email.toLowerCase()).trim()

  for (const item of blacklistedDomains.value) {
    if (normalizedEmail.endsWith(item.domain))
      return true
  }

  return false
}
