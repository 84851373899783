import { FTL_ONLY_COUNTRIES } from '@/utils/countries'
import type { Waypoint } from '@apiTypes'

export function isFTLOnlyCountry(waypoints: Waypoint[]) {
  if (waypoints) {
    const pickupCountry = waypoints[0].country || ''
    const deliveryCountry = waypoints[1].country || ''

    return FTL_ONLY_COUNTRIES.includes(pickupCountry) || FTL_ONLY_COUNTRIES.includes(deliveryCountry)
  }

  return false
}
